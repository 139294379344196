import { IResource } from '@utils/types';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function NewsTemplate({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data: {
		site: { siteMetadata: { siteUrl: string } };
		markdownRemark: {
			fields: { slug: string };
			frontmatter: IResource;
			html: string;
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
	};
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner, site } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html, fields } = markdownRemark;

	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_Insights")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<div className="news-content-header">
						<span className="news-content-header__date">
							{frontmatter.date}
						</span>
					</div>

					<div dangerouslySetInnerHTML={{ __html: html }} />

					<aside className="share__icons">
						<span>{t("_ShareThisStory")}</span>
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://www.linkedin.com/shareArticle?mini=true&url=${site?.siteMetadata?.siteUrl}${fields.slug}&title=${frontmatter.title}&summary=${frontmatter.summary}`}
							className="social-icon__item social-icon--linkedin"></a>
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://twitter.com/share?url=${site?.siteMetadata?.siteUrl}${fields.slug}&text=${frontmatter.title}`}
							className="social-icon__item social-icon--twitter"></a>
						<a
							target="_blank"
							rel="noreferrer"
							href={`https://www.facebook.com/sharer/sharer.php?u=${site?.siteMetadata?.siteUrl}${fields.slug}`}
							className="social-icon__item social-icon--facebook"></a>
					</aside>
				</div>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				date(formatString: "DD MMMM YYYY")
			}
			html
		}
		banner: file(relativePath: { eq: "content/press-releases.jpg" }) {
			...imageBreaker
		}
	}
`;
